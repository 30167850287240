main.layout-section {
  display: flex;
  flex-direction: row;

  #content {
    max-width: 45rem;
    margin-left: auto;
    margin-right: auto;

    #content-footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: flex-start;

      .youth-approved-seal {
        max-width: 10rem;
      }

      @media screen and (max-width: 32rem) {
        flex-direction: column;
        align-items: center;

        .youth-approved-seal {
          max-width: 7.5rem;
        }
      }
    }
  }
}
