header {
  background-color: var(--header-background);
  --text-color: var(--header-foreground);
  margin: 0;
  display: grid;
  grid-gap: 0;

  grid-template-areas:
    "logo        text"
    "navigation  navigation"
  ;

  #header-logo {
    grid-area: logo;
    align-self: center;
    width: 20rem;
  }

  #header-text {
    grid-area: text;
    padding-left: 1rem;
    padding-right: 2rem;
    max-width: 55rem;
    align-self: center;
    font-size: 1.2rem;

    p {
      margin: 0.1rem;

      a {
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 64rem) {
    grid-template-areas:
      "logo"
      "navigation"
    ;

    #header-logo {
      justify-self: center;
    }

    #header-text {
      display: none;
    }
  }

  #header-navigation {
    grid-area: navigation;

    a {
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    nav {
      margin: 0.25rem 1rem;

      ul {
        list-style-type: none;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, 7.5rem);
        gap: 0.75rem;
        align-items: center;

        li {
          width: 7.5rem;
          text-align: center;
        }
      }
    }

    #navigation-trigger {
      display: none;
    }

    label[for="navigation-trigger"] {
      display: none;
      cursor: pointer;
      text-align: center;
      color: var(--text-color);
    }

    @media screen and (max-width: 32rem) {
      label[for="navigation-trigger"] {
        display: block;
      }

      #navigation-trigger ~ nav {
        display: none;
      }

      nav {
        padding: 0.25rem 1rem;

        ul {
          display: block;

          li {
            display: block;
            width: 100%;
            margin: 1rem 0;
            text-align: center;
          }
        }
      }

      #navigation-trigger:checked ~ nav {
        display: block;
        //padding-bottom: 0.4rem;

        ul {
          display: block;
          text-align: center;
          padding: 0;
          grid-template-columns: 1fr;

          li {
            padding: 0.75rem 0;
          }
        }
      }
    }
  }
}
